import { useState, useEffect, useContext } from 'react'
import { LanguageContext } from '../../../context'
import { definitions } from '../../../config'
import PropTypes from 'prop-types'

const LanguageSwitch = ({ testID, current }) => {
  const ctx = useContext(LanguageContext)
  const [activeNL, setActiveNL] = useState(current === definitions.Languages.NL)
  const [activeEN, setActiveEN] = useState(current === definitions.Languages.EN)

  const setLang = (lang) => {
    ctx.setLanguage(lang)
    setActiveEN(lang === definitions.Languages.EN)
    setActiveNL(lang === definitions.Languages.NL)
  }

  return (
    <div data-testid={testID} className={`LanguageSwitch`}>
      <div
        className={`selector ${activeNL ? 'selected' : ''}`}
        onClick={() => setLang('nl')}
      >
        NL
      </div>
      <div className={`separator`}>|</div>
      <div
        className={`selector  ${activeEN ? 'selected' : ''}`}
        onClick={() => setLang('en')}
      >
        EN
      </div>
    </div>
  )
}

LanguageSwitch.propTypes = {
  testID: PropTypes.string,
  current: PropTypes.string
}

export default LanguageSwitch
