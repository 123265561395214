import PropTypes from 'prop-types'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas, fab)

const Icon = ({ testID = '', type = '', icon = '' }) => {
  return (
    <FontAwesomeIcon
      data-test-id={testID}
      icon={icon}
      data-object-type={type ?? ''}
      className={`Icon`}
    />
  )
}

const exceptionClasses = ['blue', 'white']

Icon.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses),
  icon: PropTypes.string
}

export default Icon
