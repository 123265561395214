import PropTypes from 'prop-types'
import Icon from '../Icon'

const Button = ({ testID = '', label, action }) => {
  const handler = () => {
    action()
  }

  return (
    <button data-testid={testID} className={`Button`}>
      <div>
        <Icon type={'white'} icon={'square-full'} />
      </div>
      <div>{label}</div>
    </button>
  )
}

Button.propTypes = {
  testID: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.func
}

export default Button
