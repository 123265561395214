import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextParagraph from '../TextParagraph'

const TitleLabel = ({ testID = '', background = '', label = '' }) => {
  return (
    <h2
      data-testid={testID}
      data-object-type={background ?? ''}
      className={`TitleLabel`}
    >
      {label}
    </h2>
  )
}

const exceptionClasses = ['dark', 'light']

TextParagraph.propTypes = {
  testID: PropTypes.string,
  background: PropTypes.oneOf(exceptionClasses),
  label: PropTypes.string
}

export default TitleLabel
