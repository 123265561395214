import PropTypes from 'prop-types'
import OutrightLogo from '../../../resources/image/outright-logo.png'
const Logo = ({ testID = '' }) => {
  return (
    <img
      alt='Outright Projects'
      data-testid={testID}
      className={`Logo`}
      src={OutrightLogo}
    ></img>
  )
}

Logo.propTypes = {
  testID: PropTypes.string
}

export default Logo
