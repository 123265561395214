import { useState, useEffect } from 'react'
import { termsUrl } from '../../../config'
import { useNavigate } from 'react-router-dom'

const Terms = ({ testID, type }) => {
  const navigate = useNavigate()

  useEffect(() => {
    window.open(termsUrl, '_blank')
    navigate('/')
  }, [])

  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Terms`}
    ></div>
  )
}

export default Terms
