import { mail, definitions } from '../config'

const parseContent = (lang, item) => {
  const result = {
    showTitle: item.showTitle,
    title: lang === definitions.Languages.EN ? item.title_en : item.title_nl,
    text: lang === definitions.Languages.EN ? item.content_en : item.content_nl,
    img: item.img ? item.img : false,
    mail: item.showContactButton ? mail : null
  }

  return result
}

export { parseContent }
