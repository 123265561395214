import PropTypes from 'prop-types'
import QuoteImage from '../../../resources/image/quote.png'
import Quotes from '../../../resources/image/quotes.png'
const Quote = ({ testID = '', content = {} }) => {
  return (
    <section data-testid={testID} className={`Quote`}>
      <div className={`paragraph-content`}>
        <div className={`image-content`}>&nbsp;</div>
        <div
          className={`text-content`}
          style={{ backgroundImage: `url(${Quotes})` }}
        >
          {content.text}
        </div>
        <div className={`image-content`}>
          <img src={QuoteImage} alt={''} />
        </div>
      </div>
    </section>
  )
}

Quote.propTypes = {
  testID: PropTypes.string,
  content: PropTypes.object
}

export default Quote
