import { useContext } from 'react'
import PropTypes from 'prop-types'
import Header from '../../molecules/Header'
import BlackParagraph from '../../molecules/BlackParagraph'
import Paragraph from '../../molecules/Paragraph'
import Quote from '../../molecules/Quote'
import Footer from '../../molecules/Footer'
import { definitions } from '../../../config/'
import { parseContent } from '../../../lib'
import { LanguageContext } from '../../../context'
import LanguageSwitch from '../../atoms/LanguageSwitch'

const Home = ({ testID = '', content = [] }) => {
  const ctx = useContext(LanguageContext)

  return (
    <div data-testid={testID} className={`Home`}>
      <LanguageSwitch current={ctx.language} />
      {content.map((item, index) => {
        const c = parseContent(ctx.language, item)
        const p = index % 2 === 0 ? 'left' : 'right'
        if (item.type === definitions.ArticleTypes.HEADER)
          return <Header key={`p-${item.order}`} content={c} />
        if (item.type === definitions.ArticleTypes.ARTICLE)
          return (
            <Paragraph key={`p-${item.order}`} content={c} imagePosition={p} />
          )
        if (item.type === definitions.ArticleTypes.SLOGAN)
          return <Quote key={`p-${item.order}`} content={c} />
        if (item.type === definitions.ArticleTypes.BLACK)
          return <BlackParagraph key={`p-${item.order}`} content={c} />
      })}

      <Footer />
    </div>
  )
}

Home.propTypes = {
  testID: PropTypes.string,
  content: PropTypes.array
}

export default Home
