import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const TextParagraph = ({ testID = '', background = '', content = '' }) => {
  return (
    <div
      data-testid={testID}
      data-object-type={background ?? ''}
      className={`TextParagraph`}
    >
      <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
    </div>
  )
}

const exceptionClasses = ['dark', 'light']

TextParagraph.propTypes = {
  testID: PropTypes.string,
  background: PropTypes.oneOf(exceptionClasses),
  content: PropTypes.string
}

export default TextParagraph
