import PropTypes from 'prop-types'
import Button from '../../atoms/Button'
import Image from '../../atoms/Image'
import TextParagraph from '../../atoms/TextParagraph'
import TitleLabel from '../../atoms/TitleLabel'

import ImageOverlayRight from '../../../resources/image/image-overlay-right.svg'
import ImageOverlayLeft from '../../../resources/image/image-overlay-left.svg'

const Paragraph = ({ testID = '', imagePosition = 'left', content = {} }) => {
  const i = imagePosition === 'left' ? ImageOverlayLeft : ImageOverlayRight

  const renderImage = () => {
    if (!content.img) return <div className={`image-content`} />

    return (
      <div className={`image-content`} data-object-type={imagePosition}>
        <div className={`image-overlay`}>
          <img src={i} alt={''} />
        </div>

        <img src={content.img} className={`content-image`} alt={''} />
      </div>
    )
  }

  const renderButton = () => {
    return (
      <a href={`mailto:${content.mail}`}>
        <Button label={`contact`} />
      </a>
    )
  }

  const renderTitle = () => {
    return <TitleLabel label={content.title} />
  }

  return (
    <section data-testid={testID} className={`Paragraph`}>
      <article className={`paragraph-content`}>
        {imagePosition === 'left' && renderImage()}
        <div className={`text-content`}>
          {content.showTitle && renderTitle()}
          <TextParagraph background={`light`} content={content.text} />
          {content.mail && renderButton()}
        </div>
        {imagePosition === 'right' && renderImage()}
      </article>
    </section>
  )
}

const exceptionClasses = ['left', 'right']

Paragraph.propTypes = {
  testID: PropTypes.string,
  imagePosition: PropTypes.oneOf(exceptionClasses),
  content: PropTypes.object,
  contact: PropTypes.bool
}

export default Paragraph
