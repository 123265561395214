import PropTypes from 'prop-types'
import TitleLabel from '../../atoms/TitleLabel'
import TextParagraph from '../../atoms/TextParagraph'
import Logo from '../../atoms/Logo'
import Image from '../../atoms/Image'

import { definitions } from '../../../config'

const Header = ({ testID = '', content }) => {
  return (
    <header data-testid={testID} className={`Header`}>
      <div className={`header-content`}>
        <div className={`text-content`}>
          <TitleLabel background={`dark`} label={content.title} />
          <TextParagraph background={`dark`} content={content.text} />
        </div>
        <div className={`image-content`}>
          <Image image={content.img} />
        </div>
      </div>
      <div className={`logo`}>
        <Logo />
      </div>
    </header>
  )
}

Header.propTypes = {
  testID: PropTypes.string,
  content: definitions.HeaderContent.isRequired
}

export default Header
