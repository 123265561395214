import PropTypes from 'prop-types'

const Image = ({ testID = '', image }) => {
  return (
    <div
      data-testid={testID}
      className={`Image`}
      style={{ backgroundImage: `url(${image})` }}
    ></div>
  )
}

Image.propTypes = {
  testID: PropTypes.string,
  image: PropTypes.string
}

export default Image
