import PropTypes from 'prop-types'

const HeaderContent = PropTypes.shape({
  background: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
})

const ArticleTypes = {
  ARTICLE: 'article',
  HEADER: 'header',
  BLACK: 'black',
  SLOGAN: 'slogan'
}

const Languages = {
  NL: 'nl',
  EN: 'en'
}

const definitions = {
  HeaderContent,
  ArticleTypes,
  Languages
}

export default definitions
