import { useState } from 'react'
import { Navigate, BrowserRouter, Routes, Route } from 'react-router-dom'

import { Home, Terms } from './components/pages'

import { useDatabase } from './hooks'
import { LanguageContext } from './context'
import detectBrowserLanguage from 'detect-browser-language'
import { termsUrl } from './config'

const App = () => {
  const l = detectBrowserLanguage().split('-')[0]
  const [language, setLanguage] = useState(l === 'nl' ? 'nl' : 'en')
  const [content, isLoaded] = useDatabase('articles')

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <BrowserRouter>
        {isLoaded && (
          <Routes>
            <Route index element={<Home content={content} />} />
            <Route path='/terms' element={<Terms />} />
          </Routes>
        )}
      </BrowserRouter>
    </LanguageContext.Provider>
  )
}

export default App
