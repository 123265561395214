import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TitleLabel from '../../atoms/TitleLabel'
import TextParagraph from '../../atoms/TextParagraph'
import Image from '../../atoms/Image'

const BlackParagraph = ({ testID = '', imagePosition = 'left', content }) => {
  const renderImage = () => {
    return (
      <div className={`image-content`}>
        <Image image={content.img} />
      </div>
    )
  }
  return (
    <section data-testid={testID} className={`BlackParagraph`}>
      <article className={`paragraph-content`}>
        {imagePosition === 'left' && renderImage()}
        <div className={`text-content`}>
          <TitleLabel background={`dark`} label={content.title} />
          <TextParagraph background={`dark`} content={content.text} />
        </div>
        {imagePosition === 'right' && renderImage()}
      </article>
    </section>
  )
}

const exceptionClasses = ['left', 'right']

BlackParagraph.propTypes = {
  testID: PropTypes.string,
  imagePosition: PropTypes.oneOf(exceptionClasses),
  content: PropTypes.object
}

export default BlackParagraph
