import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon'

import { mail, termsUrl } from '../../../config'

const Footer = ({ testID = '' }) => {
  return (
    <div data-testid={testID} className={`Footer`}>
      <div className={`mail`}>
        <a href={`mailto:${mail}`}>
          <Icon type={`blue`} icon={`fa-envelope`} />{' '}
          brenda@outrightprojects.com
        </a>
      </div>
      <div className={`linkedin`}>
        <a
          href='https://www.linkedin.com/company/outright-projects'
          target={`_blank`}
        >
          <Icon type={`blue`} icon={`fa-brands fa-linkedin`} /> Linked In
        </a>
      </div>
      <div className={`terms`}>
        <a target='_blank' href={termsUrl}>
          <Icon type={`blue`} icon={`fa-file-contract`} /> Terms & Conditions
        </a>
      </div>
    </div>
  )
}

Footer.propTypes = {
  testID: PropTypes.string
}

export default Footer
